@font-face {
  font-family: "MuseoSans";
  src: local("MuseoSans"), url("./fonts/MuseoSans_500.otf") format("opentype");
  font-weight: 500;
}
@font-face {
  font-family: "MuseoSans900";
  src: local("MuseoSans900"),
    url("./fonts/MuseoSans_900.otf") format("opentype");
  font-weight: 900;
}
body {
  background: url(./assets/images/bgLite.jpg) no-repeat scroll right 800px
    #0c0c1c;
  color: #fff;
  font-family: "MuseoSans", sans-serif;
  font-size: 1.2em;
}
/* ================================================

GENERAL

================================================  */
a {
  color: #f70202;
  text-decoration: none;
}
a:hover {
  color: #ff2f2f;
}
a {
  -webkit-transition: color 0.5s ease-in-out;
  -moz-transition: color 0.5s ease-in-out;
  transition: color 0.5s ease-in-out;
}

a:hover {
  -webkit-transition: color 0.5s ease-in-out;
  -moz-transition: color 0.5s ease-in-out;
  transition: color 0.5s ease-in-out;
  text-decoration: none;
}

.btn {
  -webkit-transition: background-color 0.5s ease-in-out;
  -moz-transition: background-color 0.5s ease-in-out;
  transition: background-color 0.5s ease-in-out;
  padding: 15px 24px;
}

.modal-footer .btn {
  padding: 0.25rem 0.5rem;
  font-family: "MuseoSans", sans-serif;
}

.btn:hover {
  -webkit-transition: background-color 0.5s ease-in-out;
  -moz-transition: background-color 0.5s ease-in-out;
  transition: background-color 0.5s ease-in-out;
}

h1,
h2,
h3,
h4 {
  font-family: "MuseoSans900", sans-serif;
}
.btn {
  font-family: "MuseoSans900", sans-serif;
}
.padVer {
  padding: 4em 0;
}
.padHor {
  padding: 0 1em;
}
.btn-primary {
  background: rgb(140, 35, 35);
  background: linear-gradient(
    0deg,
    rgba(140, 35, 35, 1) 0%,
    rgba(255, 0, 0, 1) 100%
  );
  border: none;
  border-radius: 50px;
  padding: 0.4em 2em;
  /* text-transform: uppercase; */
  font-size: 1em;
}
.btn-browse-now {
  font-size: 1.4em;
}
.btn-primary:hover {
  background: rgb(182, 42, 42);
  background: linear-gradient(
    0deg,
    rgba(182, 42, 42, 1) 0%,
    rgba(255, 34, 34, 1) 100%
  );
  box-shadow: none;
}

.btn-primary:active {
  background: rgb(182, 42, 42);
  background: linear-gradient(
    0deg,
    rgba(182, 42, 42, 1) 0%,
    rgba(255, 34, 34, 1) 100%
  );
  box-shadow: none;
}

.btn-primary:focus {
  background: rgb(182, 42, 42);
  background: linear-gradient(
    0deg,
    rgba(182, 42, 42, 1) 0%,
    rgba(255, 34, 34, 1) 100%
  );
  box-shadow: none;
}

.btn-titles {
  background: rgb(228, 224, 224) !important;
  background: linear-gradient(
    0deg,
    rgba(228, 224, 224, 1) 0%,
    rgb(206, 204, 204) 100%
  ) !important;
  border: none;
  border-radius: 50px;
  padding: 0.75em 3em;
  text-transform: uppercase;
  font-size: 1em;
  color: black;
}
.btn-titles:hover {
  background: rgb(168, 167, 167) !important;
  background: linear-gradient(
    0deg,
    rgba(168, 167, 167, 1) 0%,
    rgb(250, 249, 249) 100%
  ) !important;
  box-shadow: none !important;
  color: black;
}

.btn-titles:active {
  background: rgb(168, 166, 166) !important;
  background: linear-gradient(
    0deg,
    rgba(182, 42, 42, 1) 0%,
    rgba(255, 34, 34, 1) 100%
  );
  box-shadow: none;
  color: black;
}

.btn-titles:focus {
  background: rgb(182, 42, 42);
  background: linear-gradient(
    0deg,
    rgba(182, 42, 42, 1) 0%,
    rgba(255, 34, 34, 1) 100%
  );
  box-shadow: none;
  color: black;
}
.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
/* ================================================

NAV

================================================  */
/*.navbar-brand img {
  width: auto;
  height: 42px;
}*/

.navbar {
  z-index: 1002;
  background-color: transparent !important;
  background-image: linear-gradient(
    180deg,
    rgba(13, 13, 30, 0.7) 10%,
    transparent
  );
  padding: 0.5em 0;
  width: 100%;
}
.navbar .container-fluid {
  padding-right: var(--bs-gutter-x, 1rem);
  padding-left: var(--bs-gutter-x, 1rem);
}
.navbar-brand img {
  width: auto;
  height: 36px;
  cursor: pointer;
}
.navbar a {
  color: #ffffff;
  font-size: 18px;
}
.input-group {
  background: #161636;
  border: 1px solid #7c6ca7;
  border-radius: 6px;
}
#search-input {
  background: #161636;
  border: none !important;
  color: white;
}
#search-input:focus {
  box-shadow: none;
  border: none;
}
#search-input:focus-visible {
  box-shadow: none;
  border: 1px solid #7c6ca7;
}
/* ================================================

HERO VIDEO

================================================  */
.fBgs {
  position: absolute;
  width: 100%;
  z-index: 10002;
  height: 50%;
  background: url(./assets/images/FcorRight.png),
    url(./assets/images/FcorLeft.png);
  background-repeat: no-repeat;
  background-position: 110% 0%, 0% 300%;
}
.FcorRight {
  position: absolute;
  top: -60px;
  right: 0px;
  width: 426px;
  height: 236px;
  background: url(./assets/images/FcorRight.png);
}
.fCorLeft {
  position: absolute;
  bottom: -190px;
  left: -60px;
  z-index: 1;
  width: 352px;
  height: 539px;
  background: url(./assets/images/FcorLeft.png);
}
.video-container {
  z-index: 0;
  background-size: cover;
  background-position: center center;
  position: relative;
  padding-bottom: 4em;
}
.secDiv {
  background: url(./assets/images/Fcenter.png),
    url(./assets/images/sectionDiv.png);
  background-repeat: no-repeat;
  background-size: 30%, cover;
  background-position: 92% center, center;
  width: 100%;
  position: absolute;
  bottom: -125px;
  min-height: 380px;
}
#wrapper {
  margin: 0 auto;
}
/* =======
NEW
======= */
.secDiv h1,
.secDiv h2 {
  text-indent: -5000px;
}

#wrapper td {
  vertical-align: middle;
  text-align: center;
}
#wrapper img {
  position: absolute;
  top: 5%;
  left: 50%;
  width: 420px;
  height: 84px;
  /*margin-top: -210px;  Half the height */
  margin-left: -210px; /* Half the width */
}
#wrapper img.slogan {
  top: 28%;
  width: 340px;
  height: auto;
  margin-left: -170px; /* Half the width */
}
.text-intro {
  display: block;
  padding: 9em 15em 0;
  text-align: center;
}
.cta-intro {
  display: block;
  padding: 1em 0;
  text-align: center;
}

.hero-video {
  object-fit: contain;
  width: 100%;
}
/* ================================================

INTRO

================================================  */
#introCta {
  z-index: 10003;
  padding-top: 0;
}
#introCta p {
  text-align: center;
}

/* ================================================

WATCH IT EVERWHERE

================================================  */
.slick-slider {
  margin: 0;
  padding: 0 3em;
}
.slick-list {
  padding: 0;
}
.titleItem {
  margin: 0;
  /* cursor: pointer; */
}
.titlePic {
  /* background-color: #2d2d2d; */
  background-color: transparent;
  width: 90%;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}
.container-fluid {
  margin: 0;
  padding: 0;
}
.titlePic img {
  width: 100%;
  border-radius: 7px 7px 0 0;
  -webkit-border-radius: 7px 7px 0 0;
  -moz-border-radius: 7px 7px 0 0;
}
.titles-slide .titleMeta {
  background-color: #2d2d2d;
  padding: 20px;
  color: #93969b;
  font-size: 16px;
  border-radius: 0 0 7px 7px;
  width: 90%;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

.titles-slide .titleMeta h3 {
  color: #fff;
  font-family: "MuseoSans", sans-serif;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.titles-slide .metaMini {
  display: flex;
  width: 100%;
}
.titles-slide .yearTime {
  flex: 3 1;
}
.titles-slide .rating {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  background: rgb(59, 59, 59);
  background: linear-gradient(
    0deg,
    rgba(59, 59, 59, 1) 0%,
    rgba(106, 106, 106, 1) 100%
  );
  color: #fff;
  align-items: center;
  padding: 6px 10px 3px;
  line-height: 12px;
  font-size: 12px;
  white-space: nowrap;
  flex: 1;
  text-align: center;
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 32px;
  height: 32px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  text-indent: -5000px;
  z-index: 999;
}

.slick-prev {
  /* background-image: url(./assets/images/arrow-left.svg); */
  background-image: url(./assets/images/ico-left.svg);
}
.slick-next {
  /* background-image: url(./assets/images/arrow-right.svg); */
  background-image: url(./assets/images/ico-chevright.svg);
}
.slick-prev {
  left: 30px;
}
.slick-next {
  right: 20px;
}

/*element.style {
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
.slick-prev {
  left: 30px;
}
.slick-prev {
  background-image: url(../img/arrow-left.svg);
}
.slick-prev {
  left: -45px;
}
.slick-prev {
  background-image: url(../img/arrow-left.png);
}*/

/* ================================================

WATCH IT EVERWHERE

================================================  */
#watchEve {
  text-align: center;
  background: url(./assets/images/sectionDivsm.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 7em;
}

.wevery {
  position: relative;
}
.nav-tabs {
  border-bottom: none;
}
#watchEve .nav {
  display: block;
  position: absolute;
  text-align: left;
  top: 100px;
}
.wevery .wetabicon {
  background: #2e2e3a;
  width: 62px;
  height: 62px;
  border-radius: 18px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  display: inline-block;
  float: left;
  margin: 0 12px 0 0;
}
.wevery .nav-tabs .nav-item.show,
.wevery .nav-tabs .active,
.wevery .nav-tabs,
.wevery .nav-item button {
  color: #3c3c49;
  display: inline;
  background-color: transparent;
  border-color: transparent !important;
  margin: 0 0 30px;
  padding: 0;
}
.wevery .nav-tabs p {
  display: inline-block;
  margin: 0;
  padding-top: 15px;
}
.wetabicon img {
  padding-top: 16px;
}
.wetabicon img {
  opacity: 0.4;
}
.wetabicon p {
  display: none;
}
.wevery .nav-item .active .wetabicon {
  background-color: #f70202;
}
.nav-item .active .wetabicon img {
  opacity: 1;
}
.wevery .nav-tabs .active {
  color: #fff;
}
.nav-item:hover .wetabicon img {
  opacity: 1;
}
.wevery .nav-item button:hover {
  color: #fff;
}
.tabConBot {
  margin-top: 0;
}
.tabConBot a {
  display: inline-block;
  margin: 0 1.5em;
}
.tabConBot a img {
  opacity: 0.5;
  min-width: auto;
  height: 28px;
}
.tabConBot a img:hover {
  opacity: 1;
}
.sm {
  height: 22px;
}

.secDivStAl {
  background: url(./assets/images/sectionDiv.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  position: absolute;
  bottom: -0;
  min-height: 380px;
}

/* ================================================

WATCH IT EVERWHERE Tab

================================================  */
#watchEveTab {
  text-align: center;
  background: url(./assets/images/division.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-top: 7em;
  padding-bottom: 4em;
}
.weverytab {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nav-tabs {
  border-bottom: none;
}
#watchEveTab .nav {
  text-align: left;
  top: 100px;
  background-color: #202262;
  border-radius: 33px;
  padding: 0px 35px;
  max-height: 80px;
}
#watchEveTab .tabConImg {
  max-width: 90%;
  height: auto;
}
#normal-tab {
  padding-top: 1em;
}
@media (max-width: 991px) {
  #mobile-tab {
    display: block !important;
    text-align: center;
  }
  #normal-tab {
    display: none !important;
  }
}
@media (max-width: 768px) {
  #watchEveTab .nav {
    text-align: left;
    top: 100px;
    background-color: #202262;
    border-radius: 33px;
    padding: 0px 30px;
    flex-wrap: nowrap;
  }
  .weverytab .wetabicon {
    display: inline-block;
    padding: 10px 15px !important;
    display: flex;
    align-items: center;
  }
  #mobile-tab {
    display: block !important;
    text-align: center;
  }
  #normal-tab {
    display: none !important;
  }
}
@media (max-width: 420px) {
  #watchEveTab .nav {
    text-align: left;
    top: 100px;
    background-color: #202262;
    border-radius: 0px;
    padding: 0px 30px;
    flex-wrap: nowrap;
  }
}
@media (max-width: 321px) {
  .weverytab .wetabicon {
    display: inline-block;
    padding: 10px 10px !important;
    display: flex;
    align-items: center;
  }
}
.weverytab .wetabicon {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}
.weverytab .nav-tabs .nav-item.show,
.weverytab .nav-tabs .active,
.weverytab .nav-tabs,
.weverytab .nav-item button {
  color: #3c3c49;
  background-color: transparent;
  border-color: transparent !important;
  padding: 0;
  height: 100%;
}
.weverytab .nav-tabs p {
  display: inline-block;
  margin: 0;
  padding-top: 15px;
}
.weverytab .wetabicon img {
  padding-top: 0px;
}
.wetabicon img {
  opacity: 0.4;
}
.weverytab .nav-item .active .wetabicon {
  background-color: #1d1e36;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 20px;
}
.nav-item .active .wetabicon p {
  display: block !important;
  font-size: 14px;
}
.nav-item .active .wetabicon img {
  opacity: 1;
}
.weverytab .nav-tabs .active {
  color: #fff;
  height: 100%;
}
.nav-item:hover .wetabicon img {
  opacity: 1;
}
.weverytab .nav-item button:hover {
  color: #fff;
}
.tabConBot {
  margin-top: 0;
}
.tabConBot a {
  display: inline-block;
  margin: 0 1.5em;
}
.tabConBot a img {
  opacity: 0.5;
  min-width: auto;
  height: 28px;
}
.tabConBot a img:hover {
  opacity: 1;
}
.sm {
  height: 22px;
}

.secDivStAl {
  background: url(./assets/images/sectionDiv.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  position: absolute;
  bottom: -0;
  min-height: 380px;
}

/* ================================================

WATCH IT EVERWHERE

================================================  */
#noSubInStr {
  text-align: center;
  /* background: url(./assets/images/sectionDivsm.png); */
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 2em;
}
#noSubInStr p {
  display: block;
  padding: 1em 10em 0;
  text-align: center;
}

/* ====== new ======= */
#noSubInStr .tabConBot .carousel-control-prev,
#noSubInStr .tabConBot .carousel-control-next {
  position: absolute;
  top: 50%;
  /* transform: translateY(-50%); */
  width: auto;
  height: auto;
  /* background: none; */
}

#noSubInStr .tabConBot .part-lio {
  width: 180px;
  height: 20px;
  padding: 40px;
}

#noSubInStr .part-lio {
  width: 190px;
  height: 140px;
  object-fit: contain;
  padding: 20px;
  padding-top: 40px;
}

.slider-container .slick-prev,
.slider-container .slick-next {
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
}

.slider-container .slick-prev {
  left: 10px;
  background-image: url(./assets/images/ico-left.svg);
}

.slider-container .slick-next {
  right: 10px;
  background-image: url(./assets/images/ico-chevright.svg);
}

.slider-container .slick-prev:before,
.slider-container .slick-next:before {
  content: "";
}

#noSubInStr h2 {
  margin-bottom: 20px;
}

.slider-container .imglogo {
  margin: 0 10px;
}

/* ====== / end new ======= */

/* ================================================

FOOTER

================================================  */
footer {
  font-size: 16px;
  color: #ffffff;
}
.fawlogo {
  text-align: center;
}

.fawlogo img {
  display: block;
  margin: 0 auto;
  max-width: auto;
  height: 36px;
}

footer img {
  width: auto;
  height: 30px;
}
.rowline {
  background: rgb(63, 63, 124);
  background: linear-gradient(
    90deg,
    rgba(63, 63, 124, 0) 0%,
    rgba(50, 50, 103, 1) 47%,
    rgba(63, 63, 124, 0) 100%
  );
  width: 100%;
  height: 2px;
  display: block;
  margin: 2em 0 3em 0;
}
.text-legal {
  text-align: right;
}
footer a {
  color: #5a539a;
}
footer:hover a {
  color: #56567b;
}
.divfooter {
  text-align: center;
  /* margin-top: 20px; */
}

.foot {
  max-width: none;
  width: 800px;
  /* width: auto; */
  height: auto;
  display: inline-block;
}

/* ================================================

TITLES

================================================  */
.ico-logo {
  width: 180px;
  height: auto;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  text-align: center;
}

/*.navbar-brand img {
  width: auto;
  height: 42px;
}*/
.jumbotron {
  background-color: #eb1a21;
  color: #fff;
  border-radius: 0;
  text-align: right;
  padding: 0 0 3em;
  background-image: url(./assets/images/diaBg.png);
  background-position: bottom center;
  background-repeat: no-repeat;
  margin: 0;
}

.jumbotron .container {
  position: relative;
}

.jumbotron.titles {
  background-image: url(./assets/images/hero-romantic.jpg);
  background-size: cover;
}

.jumbotron.titles h1 {
  text-align: left;
  margin: 1em 0;
  font-size: 2.5em;
}

.section-titles {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}
.section-titles h2 {
  font-size: 1.7em;
}

.section-titles h2 {
  font-family: "MuseoSans", sans-serif;
  text-transform: uppercase;
}

.section-titles h4 {
  font-family: "MuseoSans", sans-serif;
  /* text-transform: uppercase; */
}

.jumbotron.titles h1 {
  font-family: "MuseoSans", sans-serif;
  text-transform: uppercase;
}

.titles .list-left {
  width: auto;
  margin: 4em auto 0 auto;
}

.titles .ico-plat {
  filter: brightness(0.9);
}

.titles .ico-plat:hover {
  filter: brightness(1);
}

.section-titles {
  /* background: #1c232c; */
  padding: 3em 0 3em 0;
}

.section-titles h2 {
  color: #fff;
}

.section-titles .titles-row {
  margin-bottom: 4em;
}

.section-titles .titles-row img {
  width: 100%;
  height: auto;
  border-radius: 6px;
}
.section-titles .marB20 {
  margin-bottom: 20px;
}
.section-titles .titleMeta {
  margin: 1em 0 3em 0;
  color: #93969b;
  width: 100%;
}

.section-titles .titleMeta p {
  font-size: 1.18em;
  color: #fff;
  margin-bottom: 0.5rem;
}

.section-titles .metaMini {
  display: flex;
  width: 100%;
}

.section-titles .yearTime {
  flex: 3;
}

.section-titles .rating {
  border-radius: 2px;
  background-color: #93969b;
  color: #fff;
  align-items: center;
  padding: 5px 10px 2px;
  line-height: 12px;
  font-size: 11px;
  white-space: nowrap;
  flex: 1;
  text-align: center;
}

.section-titles .ico-w {
  width: 100px;
  height: auto;
}

.heroSection {
  width: 100%;
  height: auto;
  position: relative;
}
.heroSection.blog {
  /*background-image: linear-gradient(
      to bottom,
      rgba(26, 28, 82, 1) 0%,
      rgba(26, 28, 82, 0) 100%
    ), url(./assets/images/heroBanner.jpg);*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.heropicCont {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.heropic {
  width: 100%;
  height: auto;
  opacity: 0.7;
}
.herotitleCont {
  padding: 33% 20% 0 20%;
  text-align: center;
  z-index: 1001;
  position: relative;
  /* top: -65px; */
}
.fw-logo {
  opacity: 1;
}

.consent-box-mobile {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
  max-height: 20%;
  background-color: #fff;
  /* box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); */
  display: flex;
  /* padding: 1em; */
  /* box-sizing: border-box; */
  z-index: 10002;
  color: #000;
}
.consent-text {
  width: 70%;
  padding: 1em;
  font-size: 0.8rem;
  color: #00000099;
  /* display: flex; */
  /* align-items: center; */
  display: block;
  overflow-y: auto;
  /* text-align: justify; */
}

/* Buttons column */
.consent-buttons {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Buttons at top and bottom */
  align-items: center; /* Center the buttons horizontally */
  padding: 1em;
  background-color: #efefef;
  font-size: 0.9em;
}

.consent-buttons button {
  width: 90%;
}

/* responsive.css */

/* ==========================
  MEDIA QUERIES
========================== */
@media (max-width: 1900px) {
}
@media (max-width: 1500px) {
  .herotitleCont {
    padding: 33% 20% 0 20%;
  }
}
@media (max-width: 1366px) {
  .herotitleCont {
    padding: 25% 20% 0 20%;
  }
}

@media (max-width: 1200px) {
  #noSubInStr p {
    padding: 1em 3em 0;
  }
  .fCorLeftDe {
    display: none;
  }
}

@media (max-width: 992px) {
  body {
    background-image: none;
  }
  /* ==========
    NEW
    ========== */
  .text-intro {
    padding: 7em 6em 0;
  }
  /* .video-container {display: none;} */
  #watchEve .nav {
    margin-top: 3em;
    display: flex;
    position: inherit;
    text-align: center;
    top: inherit;
  }
  .wevery .nav-tabs p {
    display: none;
  }
  .tabConImg {
    max-width: 90%;
    height: auto;
  }
  #watchEve .nav .nav-item {
    flex: 4;
  }

  .heropicCont {
    position: absolute;
    top: 0;
    left: 0;
    height: 70%; /* Set height to 60% of the parent element */
    width: 100%; /* Ensure it takes the full width of the parent */
    overflow: hidden;
  }
  .heropicCont img {
    position: absolute;
    top: 0;
    left: -30%;
    bottom: 0;
    right: 0;
    margin: auto; /* Center the image horizontally */
    height: 100%; /* Ensure the image fills the container vertically */
    width: auto; /* Maintain the aspect ratio */
    object-fit: cover;
  }
  .herotitleCont {
    padding: 40% 10% 0 10%;
  }
}

@media (max-width: 768px) {
  .FcorRight,
  .fCorLeft,
  .fCorLeftBlog,
  .slogan {
    display: none;
  }
  /* ==========
    NEW
    ========== */
  .video-container {
    padding-bottom: 10em;
  }
  #wrapper img {
    top: 48%;
    left: 50%;
    width: 240px;
    height: auto;
    margin-left: -120px;
  }
  /* ==========
    NEW
    ========== */
  .text-intro {
    padding: 17em 1em 0;
  }
  /*.text-intro {
    padding: 16em 1em 0 1em;
  }*/
  #noSubInStr p {
    padding: 1em;
  }
  .text-legal {
    text-align: left;
    margin-top: 3em;
  }
  .secDiv {
    background: url(./assets/images/sectionDiv.png);
    background-repeat: no-repeat;
    background-size: initial;
    background-position: center;
    width: 100%;
    position: absolute;
    bottom: -125px;
    min-height: 380px;
  }
  .text-legal {
    text-align: center;
    margin-top: 1em;
  }
  footer {
    text-align: left;
  }
  .social-icons ul {
    display: flex;
    flex-direction: row;
    justify-content: left;
  }
  .social-icons ul span {
    display: none;
  }
  .navbar-brand img {
    width: auto;
    height: 24px;
  }
  .herotitleCont {
    padding: 55% 10% 0 10%;
  }
}

@media (max-width: 540px) {
  .consent-text {
    font-size: 0.7rem;
  }
  .fti-left {
    text-align: center;
  }
  .secDiv {
    bottom: -204px;
  }
  .text-intro {
    padding: 21em 1em 0 1em;
    font-size: 15px;
  }
  .cta-intro {
    font-size: 14px;
  }
  #titles {
    margin-top: 3.5em;
  }
  /* ==========
    NEW
    ========== */
  .video-container {
    padding-bottom: 5.5em;
  }
  /*.video-container {
    padding-bottom: 3.5em;
  }*/
  .faw-orig {
    width: auto;
    height: 64px;
  }
}
@media (max-width: 480px) {
  .herotitleCont {
    padding: 79% 10% 0 10%;
  }
}
@media only screen and (max-width: 384px) and (max-height: 724px) {
  .herotitleCont {
    padding: 50% 10% 0 10%;
  }
}

@media only screen and (min-width: 393px) and (max-width: 393px) and (min-height: 659px) and (max-height: 659px) {
  .herotitleCont {
    padding: 30% 10% 0 10%;
  }
}

@media (min-width: 767px) {
  .app-install-wrapper {
    display: none;
  }
}
.fade {
  transition: opacity 0.3s linear;
}
.modal-header {
  background-color: #eb1a21;
}
.modal-body {
  color: #212529;
}
#loading {
  display: flex;
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

#loading > div:first-child {
  margin: 10% auto 0 auto !important;
}
.dummy-video {
  visibility: hidden;
  width: 1px;
  height: 1px;
}

/* ==========
NEW 
========== */
footer h4 {
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  /* margin-bottom: 1rem; */
  font-size: 1em;
  /* margin-top: 10px; */
}

footer .top-li {
  display: inline-block;
  /* margin-bottom: 1rem; */
  color: #707c86;
  font-weight: 700;
  letter-spacing: 0.7px;
}
footer li {
  list-style-type: none;
}
footer ul {
  padding-left: 0;
}
.movie-iframe {
  border: none;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.cookie-consent {
  width: 100%;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: rgb(214, 214, 214);
  background-color: rgb(60, 64, 77);
  padding: 0.5em 1.6em;
  z-index: 10003;
  display: none;
}

.cookie-text {
  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-size: 12px;
  line-height: 1.5em;
  flex: 99 1 500px;
  text-align: center;
  margin: 6px 30px 5px 0px;
}

.cookie-text a {
  text-decoration: none;
  color: rgb(169, 165, 165) !important;
}

.cookie-text a:hover {
  color: rgb(214, 214, 214) !important;
}

.consent-btn {
  margin-right: 30px;
  color: #fff;
  flex: 1 0 130px;
  /* background-color: rgb(24 193 241); */
  background-color: #d20000;
  /* background: linear-gradient(
    0deg,
    rgba(140, 35, 35, 1) 0%,
    rgba(255, 0, 0, 1) 100%
  ); */
  border-color: transparent;
  padding: 0.2em 0.5em;
  font-size: 0.7em;
  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-weight: bolder;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
  border-radius: 5px;
  min-height: 30px;
}

.consent-btn:hover {
  /* background-color: rgb(0 178 245); */
  /* background-color: #ff2f2f; */
  /* background: linear-gradient(
    0deg,
    rgba(182, 42, 42, 1) 0%,
    rgba(255, 34, 34, 1) 100%
  ); */
  cursor: pointer;
  text-decoration: underline;
}

.show-consent {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}

.gdpr-align-center {
  text-align: center;
}
.gdpr_infobar_postion_bottom {
  background-color: #3c404d;
  /* border-top: 1px solid #fff; */
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 60px;
  max-height: 400px;
  color: #dbdbdb;
  text-shadow: 2px 2px 4px rgb(0 0 0 / 45%);
  z-index: 9900;
}
.gdpr-info-bar-container {
  padding: 10px 40px;
  position: static;
  display: inline-block;
}
.gdpr-info-bar-content {
  padding-left: 30px;
  padding-right: 30px;
  text-align: left;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-size: 0.9rem;
}
.gdpr-cookie-notice {
  color: #d6d6d6;
}
#gdpr_cookie_info_bar {
  max-height: 50%;
  overflow-y: auto;
  /* padding-bottom: 20px; */
}

.cookie-list-table {
}
.cookie-list-table {
}

.cookie-list-table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.cookie-list-table th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

#gdpr_cookie_info_bar
  .moove-gdpr-info-bar-container
  .moove-gdpr-info-bar-content {
  text-align: left;
}
#gdpr_cookie_info_bar * {
  box-sizing: border-box;
}
.gdpr-button-holder {
  /* padding-left: 15px; */
  /* padding-left: 3vw; */
  padding: 1vw;
}

/*.gdpr-infobar-allow-all {
  color: #fff;
  flex: 1 0 130px;
  background-color: #d20000;
  border-color: transparent;
  padding: 0.2em 0.5em;
  font-weight: bolder;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
  border-radius: 5px;
  min-height: 30px;
}*/
/*.gdpr-infobar-dec-all {
  margin-top: 1rem;
  color: #fff;
  flex: 1 0 130px;
  background-color: #d20000;
  border-color: transparent;
  padding: 0.2em 0.5em;

  font-weight: bolder;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
  border-radius: 5px;
  min-height: 30px;
}*/

.gdpr-privacy-link {
  text-decoration: underline;
}

.gdpr-privacy-link {
  color: #d2d2d2;
}

.gdpr-privacy-link:hover {
  color: #d2d2d2;
  text-decoration: underline;
}
#gdprCloseButton {
  position: absolute;
  background: transparent;
  color: white;
  border: none;
  top: 0px;
  right: 50px;
  font-weight: bold;
  cursor: pointer;
}
.app-install-wrapper {
  background-color: #fff;
  bottom: 0;
  color: #000;
  height: auto;
  left: 0;
  position: fixed;
  z-index: 2000;
  /* padding-left: 60px;
  padding-right: 60px; */
  border-bottom: 3px solid #ff0000;
  padding: 0.5em 0.5em 1em 0.5em;
}
.app-install {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 100%;
  font-family: "museo-sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  margin-top: -10px;
  padding-right: 10px;
}
.app-install-btn {
  font-size: 14px !important;
  border-radius: 2px;
  text-transform: none;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  text-wrap: nowrap;
}
.app-install-close {
  /* display: flex; */
  color: #000000;
  font-size: 1.5em;
  position: relative;
  top: -8px;
  cursor: pointer;
}
.app-install-btn {
  cursor: pointer;
}
.app-install-close i {
  pointer-events: none;
}

/* ==========
FAQ 
========== */

.faq-accordion {
  padding: 30px 30px 60px;
  background: linear-gradient(180deg, #1a1c52 0%, #12143f 100%);
  border-radius: 27px;
}
.faq-accordion .faq-ex-cl {
  color: #525199;
}
.faq-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: transparent;
  background-clip: unset;
  border: none;
  border-radius: 25px;
  margin: 0px 30px;
  color: #ffffff;
}
.faq-card-body {
  text-align: justify;
}
.faq-card-header {
  padding: 1.5rem 0rem;
  margin-bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.03); */
  border-bottom: 1px solid #525199;
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.social-logo {
  width: 24px;
  height: auto;
  margin-right: 8px;
  margin-bottom: 4px;
  padding: 2px;
}

.social-logo-lg {
  width: 48px;
}

/* FOOTER LOGOS */

.d-apps-footer h4 {
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  margin-bottom: 1rem;
  font-size: 1em;
  /* margin-top: 10px; */
  text-align: center;
}

.d-apps-footer {
  padding: 20px;
  text-align: center;
}

.platIco {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  gap: 10px;
  text-align: center;
  padding: 0px;
}

@media (max-width: 430px) {
  .platIco {
    padding-left: 10px;
  }
  .platIco a img {
    margin-right: 20px;
  }
}

.platIco a img {
  /* height: 28px; */
  min-width: auto;
  opacity: 0.5;
  padding: 5px 5px 5px 0px;
  align-items: center;
  text-align: center;
  margin-right: 50px;
  margin-bottom: 20px;
}
img {
  vertical-align: middle;
}

.footer-link {
  display: inline-block;
}

.sm {
  width: 40px;
  height: auto;
  object-fit: contain;
}

/* NEWW FOOTER */
.d-apps h4 {
  white-space: nowrap;
  font-size: 16px;
  padding-top: 40px;
}

.d-apps {
  /* display: flex; */
  align-items: center;
  padding: 0px;
}
.nav-link {
  padding: 0.5rem 1rem;
}
.platImg {
  max-width: 20%;
  height: auto;
  margin-bottom: 10px;
  margin-right: 10px;
}
.available-text {
  color: #8227d7;
}
/* 
  Blog List 
*/
.blog-post-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
}
.blog-post-image img {
  max-width: 100%;
  height: auto;
}
.blog-post-content {
  padding: 20px;
  flex-grow: 1;
}
.blog-post-header h2 {
  font-size: 24px;
  margin-bottom: 10px;
}
.blog-post-meta {
  font-size: 14px;
  color: #777;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.blog-post-meta span {
  padding-left: 1em;
}
.button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
}
.button:hover {
  background-color: red;
  color: white;
}
@media (max-width: 948px) {
  .blog-post-container {
    flex-direction: column;
  }
  .blog-post-image {
    order: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    width: auto;
  }
  .blog-post-image img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .blog-post-content {
    width: auto;
  }
  .fCorLeftBlog {
    display: none;
  }
}
@media (max-width: 768px) {
  .blog-post-container {
    flex-direction: column;
  }
  .blog-post-image {
    order: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    width: auto;
  }
  .blog-post-image img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .blog-post-content {
    width: auto;
  }
  .fCorLeftBlog {
    display: none;
  }
}
.blog-post-container h2 {
  color: #fff;
}
.blog-post-container p {
  color: #fff;
}
.footimage img {
  width: 100%;
  height: auto;
}
.blog-post {
  border-radius: 15px;
  overflow: hidden;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 10px rgba(0, 191, 255, 0.5); */
  margin-bottom: 40px;
  border: 1px solid #4f4f8d;
  background-color: #0e0d2b;
  width: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0), 0 0 10px rgba(0, 191, 255, 0);
}
.blog-post-meta .date {
  color: #fffefe;
}
.blog-post-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
/** 
PAGINATION 
**/
.page-link {
  position: relative;
  display: block;
  color: #5f5fcc;
  /* // #0d6efd; */
  text-decoration: none;
  background-color: transparent;
  border: none;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: transparent;
  border-color: none;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
  border-color: transparent;
}
.page-link:focus {
  z-index: 3;
  color: #0a58ca;
  background-color: transparent;
  outline: 0;
  box-shadow: none;
}
.page-link:hover {
  color: #5f5fcc;
  background-color: transparent;
}
.fCorLeftBlog {
  position: absolute;
  left: -60px;
  z-index: 1;
  width: 352px;
  height: 539px;
  background: url(./assets/images/FcorLeft.png);
}
.herotitleContBlog {
  padding: 13% 20% 8% 20%;
  text-align: center;
  z-index: 1001;
  position: relative;
  /* top: -65px; */
}
.herotitle {
  font-size: 55px;
}
/*
Blog details page
*/
.blog-de-post-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid rgba(79, 79, 141, 0.7);
  padding-bottom: 2em;
}
.blog-de-post-image img {
  max-width: 100%;
  height: auto;
}
.blog-de-post-content {
  flex-grow: 1;
}
.blog-de-post-header h2 {
  font-size: 24px;
}
.blog-de-post-header2 h2 {
  font-size: 24px;
}
.blog-de-post-meta {
  font-size: 14px;
  color: #777;
}
@media (max-width: 948px) {
  .blog-de-post-container {
    flex-direction: column;
  }
  .blog-de-post-image {
    order: -1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .blog-de-post-image img {
    width: 100%;
    max-width: 100%;
    height: auto;
    min-width: 380px; /* Set a minimum width for the images */
  }
}
@media (max-width: 768px) {
  .blog-de-post-container {
    flex-direction: column;
  }
  .blog-de-post-image {
    order: -1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .blog-de-post-image img {
    width: 100%;
    max-width: 100%;
    height: auto;
    min-width: 300px; /* Set a minimum width for the images */
  }
}
.blog-de-post-container h2 {
  color: #fff;
}
.blog-de-post-container p {
  color: #fff;
}
.footimage img {
  width: 100%;
  height: auto;
}
.blog-de-post {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 40px;
  width: auto;
}
.blog-de-post-meta .date {
  color: #fffefe;
}
.blog-de-post-meta2 .date {
  color: #fffefe;
}
.blog-de-post-image {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.blogstuff-de {
  margin-top: 20px;
}
.blogstuff2 {
  margin-top: 20px;
}
.blog-de-post-header2 {
  margin-left: 40px;
}
.blog-de-post-meta2 {
  margin-left: 0px;
}
.blogstuff2 {
  margin-left: 40px;
}
.herotitleContDe {
  padding: 8% 0% 0 20%;
  text-align: left;
  z-index: 1001;
  position: relative;
  /* top: -65px; */
}
.fCorLeftDe {
  position: absolute;
  left: -60px;
  z-index: 1;
  width: 352px;
  height: 539px;
  background: url(./assets/images/FcorLeft.png);
}
.cta-intro-de {
  display: block;
  padding: 0px;
  text-align: left;
}
.herotitle-de {
  /* font-size: 40px; */
}
.fti-logo {
  max-width: 100%;
  height: auto;
}
.heropic-de {
  max-width: 100%;
  height: auto;
  opacity: 0.8;
}
.de-mob-image {
  display: none;
}
@media (max-width: 768px) {
  .de-mob-image {
    display: block;
  }
  .de-image {
    display: none;
  }
  .fCorLeftDe {
    display: none;
  }
  .herotitleContDe {
    padding: 20px;
  }
  .blog-de-post {
    margin-bottom: 0px;
  }
  .blog-de-post-content {
    margin-top: 20px;
  }
  .faw-orig {
    /* width: auto;
    height: 64px; */
  }
}
.footer-divider {
  width: 100%;
  height: auto;
}
/* 
  Blog Tiles 
*/
#watchblogtiles {
  padding-top: 5em;
  padding-bottom: 4em;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  box-shadow: 0 0 50px 50px #0c0c1c inset;
  -webkit-box-shadow: 0 0 50px 50px #0c0c1c inset;
  -moz-box-shadow: 0 0 50px 50px #0c0c1c inset;
}
.blog-tiles-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0px;
}
.blog-tiles-image img {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
  /* padding: 0 5px 0 5px; */
}
.blog-tiles-content {
  padding: 20px 10px 0px;
  flex-grow: 1;
}
.blog-tiles-header h2 {
  font-size: 24px;
  margin-bottom: 10px;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: calc(3 * 1.5 * 14px);
  line-height: 1.3;
}
.blog-tiles-meta {
  font-size: 14px;
  color: #777;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.blog-tiles-meta span {
  /* padding-left: 1em; */
  /* border-bottom: 1px solid #5a539a; */
}
.blog-tiles-meta hr {
  margin: 0.5rem 0;
  color: inherit;
  background-color: currentColor;
  border: 1px solid #5a539a;
  /* opacity: 0.25; */
  width: 30%;
}
.blog-tiles-content hr {
  margin: 0.5rem 0;
  color: inherit;
  background-color: currentColor;
  border: 1px solid #5a539a;
  /* opacity: 0.25; */
  width: 50%;
}
.button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
}
.button:hover {
  background-color: red;
  color: white;
}
@media (max-width: 948px) {
  .web-view {
    display: none !important;
  }
  .mobile-view {
    display: block !important;
  }
  .blog-tiles-container {
    flex-direction: column;
  }
  .blog-tiles-image {
    order: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    width: auto;
  }
  .blog-tiles-image img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .blog-tiles-content {
    width: auto;
  }
  .fCorLeftBlog {
    display: none;
  }
}
@media (max-width: 768px) {
  .web-view {
    display: none !important;
  }
  .mobile-view {
    display: block !important;
  }
  .blog-tiles-container {
    flex-direction: column;
  }
  .blog-tiles-image {
    order: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    width: auto;
  }
  .blog-tiles-image img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .blog-tiles-content {
    width: auto;
  }
  .fCorLeftBlog {
    display: none;
  }
}
.blog-tiles-container h2 {
  color: #fff;
}
.show-all h2 {
  color: #fff;
}
.blog-tiles-container p {
  color: #fff;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: calc(3 * 1.5 * 14px);
  font-size: 0.9rem;
  line-height: 1.5;
}
.footimage img {
  width: 100%;
  height: auto;
}
.blog-tiles {
  border-radius: 15px;
  overflow: hidden;

  margin: 40px;
  border: 1px solid #4f4f8d;
  background-color: #0e0d2b;
}
.blog-post:hover,
.blog-tiles:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 10px rgba(0, 191, 255, 0.5);
}
.blog-post:hover a,
.blog-tiles:hover a {
  background: linear-gradient(
    0deg,
    rgba(140, 35, 35, 1) 0%,
    rgba(255, 0, 0, 1) 100%
  );
  /* border: none; */
  border: 1px solid rgba(140, 35, 35, 1);
}
.learn-more-btn,
.read-more-btn {
  background: transparent;
  border: 1px solid #4f4f8d;
  /* background: linear-gradient(0deg, rgba(140, 35, 35, 1) 0%, rgba(255, 0, 0, 1) 100%); */
  border-radius: 50px;
  padding: 0.4em 2em;
  text-transform: uppercase;
  font-size: 1em;
}
.learn-more-btn {
  font-size: 0.8em;
  white-space: nowrap;
}
.blog-tiles-meta .date {
  color: #fffefe;
}
.blog-tiles-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.author-link,
.blog-link {
  /* color: #5a539a; */
  color: #fff;
}
.author-link:hover,
.blog-link:hover {
  color: #fff;
}

.cursor-auto {
  cursor: auto;
}

/* Standard placeholder styling */
::placeholder {
  color: #868e96 !important; /* Replace with your desired color */
  opacity: 1; /* Ensures full opacity across browsers */
}

/* For WebKit browsers (Chrome, Safari, etc.) */
::-webkit-input-placeholder {
  color: #868e96 !important; /* Replace with your desired color */
}

/* For Mozilla Firefox */
::-moz-placeholder {
  color: #868e96 !important; /* Replace with your desired color */
}

/* For Internet Explorer 10-11 */
:-ms-input-placeholder {
  color: #868e96 !important; /* Replace with your desired color */
}

/* For Microsoft Edge */
::-ms-input-placeholder {
  color: #868e96 !important; /* Replace with your desired color */
}
.platIco a:hover img {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
.blog-tiles-meta,
.blog-post-meta,
.read-time {
  font-size: 0.9rem;
}
.read-time {
  opacity: 0.7;
  /* letter-spacing: 1px; */
}
.blog-author {
  font-size: 0.9rem;
  opacity: 0.7;
  /* letter-spacing: 1px; */
}
.trailer-left,
.trailer-right {
  /* flex: 0 0 50%; */
}
/*.sprite {
  background: url(./assets/images/sprite.png) no-repeat top left;
  width: 100px;
  height: 100px;
}
.sprite.ico-tab-xumo {
  background-position: 0 0;
}
.sprite.ico-tab-xfinity {
  background-position: -100px 0;
}
.sprite.ico-tab-xbox {
  background-position: -200px 0;
}
.sprite.ico-tab-windows {
  background-position: -300px 0;
}
.sprite.ico-tab-vizio {
  background-position: -400px 0;
}
.sprite.ico-tab-vida {
  background-position: -500px 0;
}
.sprite.ico-tab-shaw {
  background-position: -600px 0;
}
.sprite.ico-tab-samsung {
  background-position: -700px 0;
}
.sprite.ico-tab-roku {
  background-position: -800px 0;
}
.sprite.ico-tab-rog {
  background-position: -900px 0;
}
.sprite.ico-tab-lg {
  background-position: -1000px 0;
}
.sprite.ico-tab-fretv {
  background-position: -1100px 0;
}
.sprite.ico-tab-firetv {
  background-position: -1200px 0;
}
.sprite.ico-tab-cox {
  background-position: -1300px 0;
}
.sprite.ico-tab-appletv {
  background-position: -1400px 0;
}*/
@media (max-width: 960px) {
  /*#watchblogtiles {
    background-image: url("./assets/images/blog-bg-3-sm.jpg") !important;
    background-position: left !important;
  }*/
}
@media (min-width: 768px) and (max-width: 991px) {
  .platImg {
    max-width: 15%;
  }
}

.learn-more-btn:active {
  background: transparent;
  background-color: transparent !important;
}
.learn-more-btn:focus {
  background: transparent;
  background-color: transparent !important;
}

.learn-more-btn:focus:hover {
  background: linear-gradient(
    0deg,
    rgba(182, 42, 42, 1) 0%,
    rgba(255, 34, 34, 1) 100%
  );
  box-shadow: none;
}
.consent-box {
  position: fixed;
  bottom: 2em;
  right: 2.5em;
  width: 20%;
  height: 20%;
  min-height: 200px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding: 10px; */
  z-index: 10001;
  color: #00000099;
  border-radius: 10px;
}

.consent-box .text {
  height: 65%;
  overflow-y: auto;
  padding: 1em;
}
.consent-box .text p {
  /* text-align: justify; */
  font-size: 0.9em;
  line-height: 1.4em;
  margin-bottom: 0;
  padding-bottom: 0.5em;
}

.consent-box .buttons {
  display: flex;
  justify-content: space-between;
  height: 35%;
  padding: 1em;
  background-color: #efefef;
  border-radius: 10px;
}

.consent-box .grpr-button {
  flex: 0 0 48%;
  padding: 10px;
  font-size: 0.9em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/*.consent-box button.decline {
  background-color: #f44336;
  color: white;
}*/
.consent-box .gdpr-privacy-link,
.consent-box-mobile .gdpr-privacy-link {
  color: red;
}
.consent-box .gdpr-privacy-link,
.consent-box-mobile .gdpr-privacy-link {
  color: red;
}
.gdpr-infobar-allow-all {
  background: linear-gradient(to bottom, #ff4d4d, #990000);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 10px;
  /* font-size: 16px; */
  font-weight: bold;
  cursor: pointer;
}
.gdpr-infobar-dec-all {
  background: linear-gradient(to bottom, #acacac, #424242);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 10px;
  /* font-size: 16px; */
  font-weight: bold;
  cursor: pointer;
}
#gdprCloseButton {
  position: absolute;
  /* background: transparent; */
  color: white;
  border: none;
  top: -10px;
  right: -20px;
  font-weight: bold;
  cursor: pointer;
  background: transparent;
}
#gdpr_cookie_info_bar-wr {
  /* font-family: "MuseoSans900", sans-serif; */
  font-size: 0.9rem;
  line-height: 1.9;
}
.cookie-list-table-wrapper {
  /* font-family: "MuseoSans900", sans-serif; */
  font-size: 0.8rem;
}
.consent-box-mobile .cookie-list-table-wrapper {
  /* font-family: "MuseoSans900", sans-serif; */
  font-size: 0.7rem;
}

#gdprCloseButtonSM {
  position: absolute;
  color: white;
  border: 0;
  /* top: -24px; */
  /* right: -24px; */
  font-weight: bold;
  cursor: pointer;
  background: transparent;
  top: -16px;
  left: -10px;
}
#gdprCloseButtonSM .gdpr-close-img {
  width: 60%;
}
#gdprCloseButton .gdpr-close-img {
  width: 60%;
}
.gdpr-infobar-dec-all-sm {
  color: red;
  background: none;
}

@media (max-width: 1024px) {
  .consent-box {
    display: none;
  }
  .consent-box-mobile {
    display: flex !important;
  }
}
@media (max-width: 900px) and (orientation: landscape) {
  /* Styles for mobile landscape mode */
  .consent-box-mobile {
    max-height: 32%;
  }
  .consent-text {
    font-size: 0.7rem;
  }
}
.backhome {
  background: rgb(0, 0, 0, 0.7);
  border-radius: 50px;
  font-size: 1em;
  padding: 0.4em 2em;
  border: 1px solid #fff;
  text-transform: uppercase;
}
.icon-container {
  display: flex; /* Arrange icons in a row */
  justify-content: center; /* Center icons horizontally */
  align-items: center; /* Align icons vertically */
  gap: 20px; /* Space between icons */
  padding: 20px; /* Padding around the container */
  flex-wrap: wrap; /* Allows wrapping on small screens */
}
